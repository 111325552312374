body {
  display: flex;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
}
